<script setup lang="ts">
import { DocumentType, LeaseStatus } from '@register'
import {
  useDeleteImportDocument,
  type DocumentForModal,
  type ILeaseRegisterLeaseToReview,
  type IImportDocumentToReview,
  AiAbstractStatus,
} from '@workflow'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'
import type { IPagination } from '@shared/composables/usePagination'
import { useReviewColumns } from '@workflow/components/Workflow/composables/useReviewColumns'

interface Props {
  data: IImportDocumentToReview[]
  loading?: boolean
  total?: number
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  total: 0,
})

const divisionId = getCurrentDivisionId()
const { createDivisionURL } = useDivisions()

const selectedRows = defineModel<IImportDocumentToReview[]>('selectedRows', {
  default: () => [],
})
const pagination = defineModel<IPagination>('pagination', {
  default: () => ({ page: 1, pageSize: 25 }),
})

const selectedDocuments = ref<IImportDocumentToReview[]>([])

function reviewUrl(data: IImportDocumentToReview): string {
  const lease: ILeaseRegisterLeaseToReview = data.lease
  if (!lease || !divisionId) return ''
  return createDivisionURL(`/lease/${lease.id}/lease`)
}

function reviewVariation(data: IImportDocumentToReview): string {
  const lease: ILeaseRegisterLeaseToReview = data.lease
  if (!lease || !divisionId) return ''
  return createDivisionURL(`/lease/${lease.id}/amendments`)
}

function linkDocumentUrl(data: IImportDocumentToReview): string {
  const documentId = data.id
  if (!documentId || !divisionId) return ''
  return createDivisionURL(`/link-document/${documentId}`)
}

// ARCHIVE

const deleteDocuments = computed<DocumentForModal[]>(() => {
  return selectedDocuments.value.map((doc) => ({
    id: doc.id,
    filename: doc.filename,
    name: doc.lease?.name,
  }))
})
const openDeleteConfirmation = useDeleteImportDocument(
  divisionId,
  deleteDocuments,
  'workflow',
  () => {
    selectedRows.value = []
  },
)

function showModalArchive(data: IImportDocumentToReview): void {
  selectedDocuments.value = [data]
  openDeleteConfirmation()
}

function getCompletion(document?: IImportDocumentToReview) {
  if (
    document?.lease &&
    document?.lease.summary &&
    document?.lease.summary.approvedFields &&
    document?.lease.summary.totalFields &&
    document?.lease.status
  ) {
    /*
     we might need to add 1 to the count for the lease status,
     where if the lease is status is DRAFT, it cannot be published
     */
    const approved = document?.lease.summary.approvedFields
    const totalFields = document?.lease.summary.totalFields
    return { approved, totalFields }
  }
}
function progress(document?: IImportDocumentToReview) {
  const completion = getCompletion(document)
  if (completion) {
    return (completion.approved / completion.totalFields) * 100
  }
  return 0
}

function progressLabel(document?: IImportDocumentToReview) {
  const completion = getCompletion(document)
  if (completion) {
    return `${completion.approved}/${completion.totalFields}`
  }
}

function progressTooltip(document?: IImportDocumentToReview) {
  const percentage = progress(document)

  if (percentage >= 100) {
    return `All required fields were approved, it can be moved to portfolio`
  }

  if (
    document?.lease.summary?.approvedFields ===
      document?.lease.summary?.totalFields &&
    document?.lease.status === LeaseStatus.DRAFT
  ) {
    return `Missing the lease status`
  }
  return `${Math.round(percentage)}% of the required fields were approved`
}

const columns = useReviewColumns()
</script>

<template>
  <div>
    <DataTable
      v-model:pagination="pagination"
      v-model:selection="selectedRows"
      :total-records="total"
      :columns="columns"
      :data="data"
      :loading="loading"
      :sticky="{ offsetHeader: 56 }"
      padding="md"
    >
      <template #empty>
        <div class="mt-8 flex flex-col items-center justify-center pb-12 pt-8">
          <h1>No documents found</h1>
          <Icon name="no-lease" class="my-8 text-8xl text-gray-200" />
          <p>Upload some documents to be reviewed</p>
        </div>
      </template>

      <template
        #item-leaseName="{
          item: document,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <NuxtLink
          v-if="
            document.documentType === DocumentType.LEASE ||
            document.documentType === DocumentType.SUBLEASE
          "
          :to="reviewUrl(document)"
          class="md:min-w-xs hover:text-primary focus:text-primary group-hover/tr:text-primary flex min-w-[180px] hover:underline"
        >
          {{ document.lease?.name || '-' }}
        </NuxtLink>

        <NuxtLink
          v-else-if="document?.aiAbstractStatus === AiAbstractStatus.LINKED"
          :to="reviewVariation(document)"
          class="md:min-w-xs hover:text-primary group-hover/tr:text-primary flex min-w-[180px] text-gray-500 hover:underline"
        >
          This amendment needs to be reviewed
        </NuxtLink>

        <NuxtLink
          v-else
          :to="linkDocumentUrl(document)"
          class="md:min-w-xs hover:text-primary group-hover/tr:text-primary flex min-w-[180px] text-gray-500 hover:underline"
        >
          Document needs to be attached to original Lease
        </NuxtLink>
      </template>

      <template
        #item-filename="{
          value: filename,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <span class="flex min-w-[180px] break-words">
          {{ filename || '-' }}
        </span>
      </template>

      <template
        #item-documentType="{
          item: document,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <LeaseReviewDocumentType :document="document" />
      </template>

      <template
        #item-completedAt="{
          value: completedAt,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <time class="whitespace-nowrap">
          {{ completedAt ?? '-' }}
        </time>
      </template>

      <template
        #item-leaseSummary="{
          item: document,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <div
          v-if="
            document.documentType === DocumentType.LEASE ||
            document.documentType === DocumentType.SUBLEASE
          "
          class="flex justify-center"
        >
          <LeaseCompletion
            v-if="
              document.lease &&
              document.lease.summary &&
              document.lease.summary.approvedFields &&
              document.lease.summary.totalFields
            "
            :title="progressLabel(document)"
            :progress="progress(document)"
            :tooltip="{ content: progressTooltip(document) }"
          />
          <div v-else class="text-xs">
            <span class="flex w-20 cursor-not-allowed">
              Progress not available
            </span>
          </div>
        </div>
      </template>

      <template
        #item-canPublish="{
          value: canPublish,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <span class="flex justify-center">
          <Icon v-if="canPublish" name="check" />

          <VTooltip
            v-else
            class="inline-flex"
            :delay="10"
            placement="top"
            :distance="12"
            :auto-hide="true"
          >
            <Icon name="close" />

            <template #popper>
              <div class="text-xs">
                Moving lease to portfolio requires to approve the minimum
                required fields and change the status other than draft.
              </div>
            </template>
          </VTooltip>
        </span>
      </template>

      <template
        #item-actions="{
          item: obj,
        }: TanstackTableColumn<IImportDocumentToReview>"
      >
        <div class="float-end flex max-w-max justify-end gap-2">
          <!-- DELETE -->
          <div
            class="flex opacity-0 transition-opacity duration-100 group-hover/tr:opacity-100"
          >
            <span
              role="button"
              tabindex="0"
              class="flex h-10 w-10 cursor-pointer items-center justify-center self-center rounded-lg text-red-500 transition-colors duration-100 hover:bg-red-500 hover:text-red-100"
              @click="showModalArchive(obj)"
              @keydown.enter="showModalArchive(obj)"
            >
              <Icon name="trash" filled />
            </span>
          </div>

          <!-- Review -->
          <NuxtLink
            v-if="
              obj.documentType === DocumentType.LEASE ||
              obj.documentType === DocumentType.SUBLEASE
            "
            :to="reviewUrl(obj)"
            class="group/button hover:bg-primary focus:bg-primary focus:ring-primary/20 group-hover/tr:text-primary my-2 flex items-center rounded-lg p-2 font-semibold transition-colors duration-100 hover:!text-black focus:!text-black focus:outline-none focus:ring"
          >
            <div v-if="reviewUrl(obj)" class="flex whitespace-nowrap">
              <Icon
                name="edit"
                filled
                class="group-hover/tr:text-primary mr-1.5 flex self-center group-hover/button:!text-black"
              />
              <span> Review </span>
            </div>
          </NuxtLink>
          <!-- Review amendment -->
          <NuxtLink
            v-else-if="obj?.aiAbstractStatus === 'linked'"
            :to="reviewVariation(obj) + '?id=' + obj.id"
            class="group/button hover:bg-primary group-hover/tr:text-primary my-2 flex items-center whitespace-nowrap rounded-lg p-2 font-semibold hover:!text-black"
          >
            <Icon
              name="file-attachment"
              filled
              class="group-hover/tr:text-primary mr-1.5 flex self-center text-white group-hover/button:!text-black"
            />
            Review amendment
          </NuxtLink>

          <!-- Attach amendment to a lease -->
          <NuxtLink
            v-else
            :to="linkDocumentUrl(obj)"
            class="group/button hover:bg-primary group-hover/tr:text-primary my-2 flex items-center whitespace-nowrap rounded-lg p-2 font-semibold hover:!text-black"
          >
            <Icon
              name="file-attachment"
              filled
              class="group-hover/tr:text-primary mr-1.5 flex self-center group-hover/button:!text-black"
            />
            Attach to a lease
          </NuxtLink>
        </div>
      </template>
    </DataTable>
  </div>
</template>
