import { createColumnHelper } from '@tanstack/vue-table'
import type { IImportDocumentToReview } from '@workflow'
import { getLeaseDateFormat } from '~/utils/getLeaseDateFormat'
import { breakpointsTailwind } from '@vueuse/core'

export const useReviewColumns = () => {
  const dayjs = useDayjs()
  const columnHelper = createColumnHelper<IImportDocumentToReview>()
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const greaterOrEqualMd = breakpoints.greaterOrEqual('md')

  return computed(() => [
    columnHelper.accessor('lease.name', {
      id: 'leaseName',
      header: 'Lease Name',
      meta: {
        fixed: () => greaterOrEqualMd.value && 'left',
      },
    }),
    columnHelper.accessor('filename', {
      header: 'Original file name',
    }),
    columnHelper.accessor('completedAt', {
      header: 'Updated at',
      cell: ({ getValue }) => {
        // TODO: Can't get the date format from lease from IImportDocumentToReview
        const completedAt = getValue()
        return completedAt
          ? dayjs(completedAt).format(getLeaseDateFormat())
          : undefined
      },
    }),
    columnHelper.accessor('documentType', {
      header: 'Type',
    }),
    columnHelper.accessor('lease.summary', {
      id: 'leaseSummary',
      header: 'Progress',
    }),
    columnHelper.display({
      id: 'actions',
      meta: {
        fixed: () => greaterOrEqualMd.value && 'right',
      },
    }),
  ])
}
