/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AiAbstractStatus {
    TO_REVIEW = 'toReview',
    LINKED = 'linked',
    ACCEPTED = 'accepted',
    DELETED = 'deleted',
}
